import Utils from '../utils';

export default class Core {
  constructor() {
    window.initCiblerCore = () => {
      window.removeEventListener('mouseover', window.initCiblerCore);
      window.removeEventListener('scroll', window.initCiblerCore);
      clearTimeout(this.timer);
      setTimeout(() => {
        this.init();
      }, 1000);
    };

    const ciblerTriggerCampaign = Utils.getQueryString('ciblerTriggerCampaign');
    const triggerUserGroup = Utils.getQueryString('triggerUserGroup');

    if (ciblerTriggerCampaign || triggerUserGroup) {
      this.clearLocalData(); // Clear lock when triggered
      setTimeout(() => {
        window.initCiblerCore();
      }, 100);
    } else {
      Utils.ciblerready(() => {
        if (Utils.getQueryString('cibler_auth')) {
          window.initCiblerCore();
        } else {
          this.timer = setTimeout(() => {
            window.initCiblerCore();
          }, 1000);
          window.addEventListener('mouseover', window.initCiblerCore, { passive: true });
          window.addEventListener('scroll', window.initCiblerCore, { passive: true });
        }
      });
    }
  }

  clearLocalData = () => {
    localStorage.removeItem('ciblerDailyLock');
    localStorage.removeItem('ciblerDailyCampaignLock');
  };

  init() {
    Utils.getAjax(
      `https://prod.winitout.com/api/customers/${this.customerId}/configuration`,
      (result) => {
        const config = JSON.parse(result);
        if (!localStorage.getItem('ciblerOpeningRate')) {
          localStorage.setItem('ciblerOpeningRate', Math.random() * 100);
        }

        const openRand = parseFloat(localStorage.getItem('ciblerOpeningRate'));
        window.WIOCONFIG = {
          ...window.WIOCONFIG,
          ...config,
          openRand,
        };

        if (!config.openingRate || openRand < config.openingRate) {
          this.insertTracking(config);
          this.insertRender(config);
        }
      },
      () => undefined,
    );
  }

  insertTracking = (config) => {
    let url = `https://cdn.winitout.com/static/${this.customerId}/`;
    if (config.trackingVersion) {
      url = 'https://cdn.winitout.com/static/';
      if (config.customTracking) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.trackingVersion}/`;
    }

    window.WIOCONFIG.trackingUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerTrackingScript');
    s.setAttribute('src', `${url}wiotracking.js`);
    document.head.appendChild(s);
  };

  insertRender = (config) => {
    let url = `https://cdn.winitout.com/static/${this.customerId}/`;
    if (config.renderVersion) {
      url = 'https://cdn.winitout.com/static/';
      if (config.customRender) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.renderVersion}/`;
    }

    window.WIOCONFIG.renderUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerRenderScript');
    s.setAttribute('src', `${url}wiorender.js`);
    document.head.appendChild(s);
  };
}
